var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cbo-search-pdf-container" }, [
    _c("div", { staticClass: "cbo-search-pdf-content" }, [
      _c("img", {
        staticClass: "cbo-search-pdf-logo",
        attrs: { src: require("../../../../../assets/logo-cor-primaria.png") },
      }),
      _vm._m(0),
      _c("div", { staticClass: "cbo-search-pdf-card" }, [
        _c("div", { staticClass: "cbo-search-pdf-term" }, [
          _vm._m(1),
          _c("div", { staticClass: "cbo-search-pdf-term-text-container" }, [
            _c("div", { staticClass: "cbo-search-pdf-term-title" }, [
              _vm._v("Pesquisa realizada por descrição de atividades"),
            ]),
            _c("div", { staticClass: "cbo-search-pdf-term-text" }, [
              _vm._v(" " + _vm._s(_vm.cboSearchTerm) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "cbo-search-pdf-table-title" }, [
          _vm._v(
            " Recomendações Agnes com base na pesquisa por descrição de atividades "
          ),
        ]),
        _c("div", { staticClass: "cbo-search-pdf-table-container" }, [
          _c("table", { staticClass: "cbo-search-pdf-table" }, [
            _c("thead", [
              _c(
                "tr",
                { staticClass: "cbo-search-pdf-thead-row" },
                _vm._l(_vm.cboSearchHeader, function (headerItem) {
                  return _c("th", { staticClass: "cbo-search-pdf-th" }, [
                    _vm._v(" " + _vm._s(headerItem.text) + " "),
                  ])
                }),
                0
              ),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.cboSearchTableItems, function (tableItem) {
                return _c(
                  "tr",
                  {
                    staticClass: "cbo-search-pdf-table-row",
                    class: _vm.rowClasses(tableItem),
                  },
                  _vm._l(tableItem, function (item, index) {
                    return _c("td", [
                      _c("div", { class: _vm.compatibilityTextClass(item) }, [
                        _vm._v(" " + _vm._s(item) + " "),
                        index === "compatibility" &&
                        _vm.isTotalCompatibility(item)
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "cbo-search-pdf-td-warning-icon material-symbols-outlined cbo-search-pdf-line-height-icon",
                              },
                              [_vm._v(" > warning ")]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                )
              }),
              0
            ),
          ]),
        ]),
        _vm.isTotalCompatibility(_vm.cboSearchTableItems[0].compatibility)
          ? _c("div", [_vm._m(2)])
          : _vm._e(),
      ]),
    ]),
    _vm._m(3),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cbo-search-pdf-header" }, [
      _c("div", { staticClass: "cbo-search-pdf-title" }, [
        _vm._v("Pesquisa | Classificação Brasileira de Ocupações (CBO)"),
      ]),
      _c("div", { staticClass: "cbo-search-pdf-subtitle" }, [
        _vm._v(
          " Recomendações de CBO com base na pesquisa por descrição de atividades. Essas e outras recomendações você encontra na Agnes com base na Classificação Brasileira de Ocupações. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cbo-search-pdf-term-icon" }, [
      _c(
        "span",
        {
          staticClass:
            "material-symbols-outlined cbo-search-pdf-line-height-icon",
        },
        [_vm._v(" search ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cbo-search-pdf-100-alert" }, [
      _c("div", [
        _c("span", { staticClass: "material-symbols-outlined" }, [
          _vm._v(" warning "),
        ]),
        _c("strong", [_vm._v(" Atenção! ")]),
        _vm._v(
          " O texto é uma cópia da descrição sumária prevista na Classificação Brasileira de Ocupações. Recomendamos aplicar uma descrição que seja fidedigna com as atividades efetivamente realizadas pelo trabalhador(a). "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cbo-search-pdf-footer" }, [
      _c("img", {
        staticClass: "cbo-search-pdf-footer-img",
        attrs: { src: require("../../../../../assets/footer_image.png") },
      }),
      _c("img", {
        staticClass: "cbo-search-pdf-footer-img-info",
        attrs: {
          src: require("../../../../../assets/agnes_pdf_footer_info.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }