var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cbo-search-container" },
    [
      _c(
        "v-row",
        {
          staticClass: "cbo-search-card",
          attrs: { justify: "center", "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: _vm.columnSpacingSize } }),
          _c(
            "v-col",
            { attrs: { cols: _vm.columnContentSize } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row cbo-search-input-container align-center",
                  class: _vm.searchInputDoneClass,
                },
                [
                  _c(
                    "div",
                    { staticClass: "cbo-search" },
                    [
                      _c("TableSearch", {
                        attrs: {
                          label: _vm.txt.searchLabel,
                          disabled: _vm.cboSearchTableLoading,
                        },
                        on: { input: (items) => _vm.onInputSearch(items) },
                        model: {
                          value: _vm.textToSearch,
                          callback: function ($$v) {
                            _vm.textToSearch = $$v
                          },
                          expression: "textToSearch",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showTable
                    ? _c(
                        "AGTooltip",
                        {
                          staticClass: "mr-auto",
                          attrs: {
                            "large-tooltip": true,
                            "large-tooltip-icon": "help",
                            "large-tooltip-title":
                              _vm.txt.searchInstructionTitle,
                            "large-tooltip-icon-class":
                              "material-symbols-outlined",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "largeTooltipContent",
                                fn: function () {
                                  return [
                                    _c("CboSearchInstructions", {
                                      attrs: { "is-tooltip": "" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2952883692
                          ),
                        },
                        [
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row align-center cbo-search-instruction-tooltip",
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "material-icons-outlined cbo-search-instruction-tooltip-icon",
                                  },
                                  [_vm._v("info")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.txt.searchInstructionTitle) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.showTable
                    ? _c(
                        "AGButton",
                        {
                          staticClass: "justify-end",
                          attrs: {
                            disabled: _vm.disableExportButton,
                            type: "tertiary",
                          },
                          on: { click: _vm.createPDF },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "material-icons-outlined" },
                            [_vm._v("picture_as_pdf")]
                          ),
                          _vm._v(" " + _vm._s(_vm.txt.export) + " "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.showTable
                ? _c("AlertBox", {
                    class: _vm.columnContentPadding,
                    attrs: {
                      type: "info",
                      title: _vm.txt.searchInstructionTitle,
                      "has-extra-container": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "container-extra",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "cbo-search-alert-text" },
                                [_c("CboSearchInstructions")],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      253233658
                    ),
                  })
                : _vm._e(),
              _vm.showTable
                ? _c("CboSearchTable", {
                    staticClass: "pt-4",
                    attrs: {
                      "loading-table": _vm.cboSearchTableLoading,
                      "table-title": _vm.txt.tableTitle,
                      headers: _vm.cboTableHeader,
                      items: _vm.cboSearchTableItems,
                      "total-items": _vm.cboSearchTableItemsTotal,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "3" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }