<template>
  <div class="cbo-search-container">
    <v-row justify="center" no-gutters class="cbo-search-card">
      <v-col :cols="columnSpacingSize"></v-col>
      <v-col :cols="columnContentSize">
        <div class="d-flex flex-row cbo-search-input-container align-center" :class="searchInputDoneClass">
          <div class="cbo-search">
            <TableSearch
              v-model="textToSearch"
              :label="txt.searchLabel"
              :disabled="cboSearchTableLoading"
              @input="(items) => onInputSearch(items)"
            />
          </div>
          <AGTooltip
            v-if="showTable"
            class="mr-auto"
            :large-tooltip="true"
            large-tooltip-icon="help"
            :large-tooltip-title="txt.searchInstructionTitle"
            large-tooltip-icon-class="material-symbols-outlined"
          >
            <template>
              <div class="d-flex flex-row align-center cbo-search-instruction-tooltip">
                <v-icon class="material-icons-outlined cbo-search-instruction-tooltip-icon">info</v-icon>
                {{ txt.searchInstructionTitle }}
              </div>
            </template>
            <template v-slot:largeTooltipContent>
              <CboSearchInstructions is-tooltip />
            </template>
          </AGTooltip>
          <AGButton
            v-if="showTable"
            class="justify-end"
            :disabled="disableExportButton"
            type="tertiary"
            @click="createPDF"
          >
            <v-icon class="material-icons-outlined">picture_as_pdf</v-icon> {{ txt.export }}
          </AGButton>
        </div>
        <AlertBox
          v-if="!showTable"
          :class="columnContentPadding"
          type="info"
          :title="txt.searchInstructionTitle"
          has-extra-container
        >
          <template v-slot:container-extra>
            <div class="cbo-search-alert-text">
              <CboSearchInstructions />
            </div>
          </template>
        </AlertBox>
        <CboSearchTable
          v-if="showTable"
          class="pt-4"
          :loading-table="cboSearchTableLoading"
          :table-title="txt.tableTitle"
          :headers="cboTableHeader"
          :items="cboSearchTableItems"
          :total-items="cboSearchTableItemsTotal"
        >
        </CboSearchTable>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ToastMixin from "@/mixins/Toast.js";
import CboSearchResultExport from './CboSearchResultExport.vue';

export default {
  name: 'CboSearch',
  components: {
    TableSearch: () => import('../../../commons/TableSearch.vue'),
    AlertBox: () => import('../../../commons/AlertBox.vue'),
    AGButton: () => import('../../../commons/AGButton.vue'),
    AGTooltip: () => import('../../../commons/AGTooltip.vue'),
    CboSearchInstructions: () => import('./CboSearchInstructions.vue'),
    CboSearchTable: () => import('./CboSearchTable.vue'),
  },
  mixins: [ToastMixin],
  data() {
    return {
      tabSelected: 0,
      textToSearch: '',
      loadingTable: false,
      showTable: false,
      cboTableHeader: [
        { text: '', value: 'idColumn', width: '30px', sortable: false },
        { text: 'Compatibilidade', value: 'compatibility', width: '50px', sortable: true },
        { text: 'Ocupação', value: 'occupation', width: '100px', sortable: true },
        { text: 'Escolaridade mínima', value: 'education', width: '100px', sortable: true },
        { text: 'Órgão de classe', value: 'professionalRegulatoryBody', width: '100px', sortable: true },
      ],

      txt: {
        tabSearch: 'Pesquisa',
        searchLabel: 'Pesquisar por descrição das atividades do trabalhador',
        searchInstructionTitle: 'Instruções de pesquisa',
        tableTitle: 'Recomendações',
        export: 'Exportar',
      },
    };
  },

  computed: {
    ...mapGetters({
      cboSearchTableLoading: 'esocial/esocialCbo/getCboSearchTableLoading',
      cboSearchTableItems: 'esocial/esocialCbo/getCboSearchTableItems',
      cboSearchTableItemsTotal: 'esocial/esocialCbo/getCboSearchTableItemsTotal',
      cboSearchTerm: 'esocial/esocialCbo/getCboSearchTerm',
      cboSearchLoadingExport: 'esocial/esocialCbo/getCboSearchLoadingExport',
      cboSearchScheduleReport: 'esocial/esocialCbo/getCboSearchScheduleReport'
    }),

    columnSpacingSize() {
      return this.showTable ? '3' : '0';
    },

    columnContentSize() {
      return this.showTable ? '12' : '6';
    },

    columnContentPadding() {
      return this.showTable ? 'px-4' : '';
    },

    searchInputDoneClass() {
      return this.showTable ? 'cbo-search-done' : '';
    },

    disableExportButton() {
      return this.cboSearchLoadingExport || this.cboSearchTableLoading;
    }
  },

  methods: {
    ...mapActions('esocial/esocialCbo', ['fetchCboSuggestion', 'sendPDF', 'setCboSearchLoadingExportButton']),
    onInputSearch(text) {
      if ([undefined, '', null].includes(text)) {
        this.showTable = false;
      } else {
        this.showTable = true;
      }
      this.fetchCboSuggestion(text);
    },

    async createPDF() {
      this.setCboSearchLoadingExportButton(true);
      try {
        let ComponentClass = Vue.extend(CboSearchResultExport);
        let instance = new ComponentClass({
          propsData: {
            cboSearchTableItemsTotal: this.cboSearchTableItemsTotal,
            cboSearchTableItems: this.cboSearchTableItems,
            cboSearchHeader: this.cboTableHeader,
            cboSearchTerm: this.cboSearchTerm,
          },
        });

        instance.$mount();
        let timestamp = new Date().getTime();
        let html = instance.$el;

        let opt = {
          margin: 0,
          filename: `Agnes_cbos_${timestamp}.pdf`,
          pagebreak: { avoid: ['tr', 'td'] },
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { scale: 2, logging: true, dpi: 300, letterRendering: true, width: 1920 },
          jsPDF: { unit: 'px', format: 'a4', orientation: 'l', hotfixes: ['px_scaling'] },
        };
        const blob = await html2pdf().set(opt).from(html).outputPdf('blob');
        await this.sendPDF(blob);

        if(this.cboSearchScheduleReport) {
          this.showToast(
            'Sua solicitação de exportação foi recebida com sucesso! Faça o download assim que o arquivo estiver disponível no menu "Analytics" na opção "Documentos".',
            'success',
            78
          );
        }
      } catch (e) {
        console.error('Error exporting data to PDF: ', e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/variables.scss';

.cbo-search-container {
  display: flex;
  flex-direction: column;
  padding: 0;

  .cbo-search-card {
    padding-bottom: 48px;

    .cbo-search-input-container {
      padding-top: 48px;
      padding-bottom: $spacing-inset-07;
      width: 100%;

      &.cbo-search-done {
        padding: $spacing-inset-05 $spacing-inset-05 0 $spacing-inset-05;

        .cbo-search {
          width: 50%;
        }
      }

      .cbo-search {
        width: 100%;

        ::v-deep {
          .ag-search-input {
            min-width: 100% !important;
          }
        }
      }

      .cbo-search-instruction-tooltip {
        margin-left: 10px;
        cursor: help;
        color: $feedback-color-information-medium;
        letter-spacing: 0.16px;
        line-height: 25px;

        .cbo-search-instruction-tooltip-icon {
          margin-right: $spacing-03;
          font-size: $font-size-xs;
          color: $neutral-color-high-light;
        }
      }
    }

    .cbo-search-alert-text {
      letter-spacing: 0.16px;
      line-height: $line-height-lg;
    }
  }
}
</style>
