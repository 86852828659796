<template>
  <div class="cbo-search-pdf-container">
    <div class="cbo-search-pdf-content">
      <img class="cbo-search-pdf-logo" src="../../../../../assets/logo-cor-primaria.png" />
      <div class="cbo-search-pdf-header">
        <div class="cbo-search-pdf-title">Pesquisa | Classificação Brasileira de Ocupações (CBO)</div>
        <div class="cbo-search-pdf-subtitle">
          Recomendações de CBO com base na pesquisa por descrição de atividades. Essas e outras recomendações você
          encontra na Agnes com base na Classificação Brasileira de Ocupações.
        </div>
      </div>
      <div class="cbo-search-pdf-card">
        <div class="cbo-search-pdf-term">
          <div class="cbo-search-pdf-term-icon">
            <span class="material-symbols-outlined cbo-search-pdf-line-height-icon"> search </span>
          </div>
          <div class="cbo-search-pdf-term-text-container">
            <div class="cbo-search-pdf-term-title">Pesquisa realizada por descrição de atividades</div>
            <div class="cbo-search-pdf-term-text">
              {{ cboSearchTerm }}
            </div>
          </div>
        </div>
        <div class="cbo-search-pdf-table-title">
          Recomendações Agnes com base na pesquisa por descrição de atividades
        </div>
        <div class="cbo-search-pdf-table-container">
          <table class="cbo-search-pdf-table">
            <thead>
              <tr class="cbo-search-pdf-thead-row">
                <th v-for="headerItem in cboSearchHeader" class="cbo-search-pdf-th">
                  {{ headerItem.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tableItem in cboSearchTableItems"
                class="cbo-search-pdf-table-row"
                :class="rowClasses(tableItem)"
              >
                <td v-for="(item, index) in tableItem">
                  <div :class="compatibilityTextClass(item)">
                    {{ item }}
                    <span
                      v-if="index === 'compatibility' && isTotalCompatibility(item)"
                      class="cbo-search-pdf-td-warning-icon material-symbols-outlined cbo-search-pdf-line-height-icon"
                    >
                      > warning
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="isTotalCompatibility(cboSearchTableItems[0].compatibility)">
          <div class="cbo-search-pdf-100-alert">
            <div>
              <span class="material-symbols-outlined"> warning </span>
              <strong> Atenção! </strong>
              O texto é uma cópia da descrição sumária prevista na Classificação Brasileira de Ocupações. Recomendamos
              aplicar uma descrição que seja fidedigna com as atividades efetivamente realizadas pelo trabalhador(a).
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cbo-search-pdf-footer">
      <img class="cbo-search-pdf-footer-img" src="../../../../../assets/footer_image.png" />
      <img class="cbo-search-pdf-footer-img-info" src="../../../../../assets/agnes_pdf_footer_info.png" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CboSearchResultExport',

  components: {},

  props: {
    cboSearchTableItems: {
      type: Array,
    },

    cboSearchTableItemsTotal: {
      type: Number,
    },

    cboSearchHeader: {
      type: Array,
    },

    cboSearchTerm: {
      type: String,
    },
  },

  data() {
    return {
      txt: {
        tableTitle: 'Recomendações',
      },
    };
  },

  methods: {
    isTotalCompatibility(compatibility) {
      return compatibility === '100%';
    },

    rowClasses(item) {
      return this.isTotalCompatibility(item.compatibility) ? 'cbo-search-pdf-total-compatibility-row' : '';
    },

    compatibilityTextClass(compatibility) {
      return this.isTotalCompatibility(compatibility) ? 'cbo-search-pdf-total-compatibility-text' : '';
    },
  },
};
</script>
<style socped>
.cbo-search-pdf-container {
  width: 1920px;
  height: auto;
}

.cbo-search-pdf-result {
  background-color: white;
  font-family: Roboto;
  font-style: normal;
  width: 100%;
}

.cbo-search-pdf-content {
  padding: 32px;
}

.cbo-search-pdf-logo {
  width: 175px;
  height: 53px;
}

.cbo-search-pdf-title {
  font-family: Roboto;
  color: #442d96;
  font-size: 32px;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 0.32px;
}

.cbo-search-pdf-header {
  margin: 25px 0;
}

.cbo-search-pdf-subtitle {
  font-family: Roboto;
  color: #52526b;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
}

.cbo-search-pdf-card {
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.cbo-search-pdf-term {
  border-radius: 8px;
  border: 1px solid #8686a2;
  background: #fff;
  padding: 12px 16px;
  display: flex;
  flex-flow: row;
  width: 100%;
}

.cbo-search-pdf-term-icon {
  margin-right: 8px;
  color: #442d96;
}

.cbo-search-pdf-term-text-container {
  display: flex;
  flex-flow: column;
}

.cbo-search-pdf-term-title {
  color: #442d96;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  letter-spacing: 0.12px;
}

.cbo-search-pdf-term-text {
  color: #182026;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
}

.cbo-search-pdf-table-title {
  color: #442d96;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.2px;
}

.cbo-search-pdf-table-container,
.cbo-search-pdf-table {
  width: 100%;
}

.cbo-search-pdf-table {
  border-radius: 4px;
  border: 1px solid #e7e7fa;
}

.cbo-search-pdf-thead-row {
  background-color: #e7e7fa;
  color: #442d96;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 0.14px;
  margin: 0;
}

.cbo-search-pdf-th {
  text-align: start;
}

.cbo-search-pdf-table-row td,
.cbo-search-pdf-thead-row th {
  padding: 16px;
}

.cbo-search-pdf-table-row:nth-of-type(even) {
  background-color: #f7f7fd;
}

.cbo-search-pdf-table-row td {
  color: #52526b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.16px;
  margin: 16px 0;
}

.cbo-search-pdf-table-row td div {
  display: flex;
  align-items: center;
}

.cbo-search-pdf-td-warning-icon {
  margin-left: 8px;
}

.cbo-search-pdf-total-compatibility-row {
  background-color: #f2f1e1;
}

.cbo-search-pdf-total-compatibility-text {
  color: #615700;
  line-height: 150%;
}

.cbo-search-pdf-line-height-icon {
  line-height: 25px;
}

.cbo-search-pdf-100-alert {
  display: flex;
  align-items: center;
  padding: 16px;
  color: #615700;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.16px;
  border: 1px solid #807726;
  border-radius: 8px;
  background-color: #f2f1e1;
}

.cbo-search-pdf-footer {
  padding-top: 32px;
  border-top: 1px solid black;
}

.cbo-search-pdf-footer-img {
  height: 104px;
  width: 100%;
  break-inside: avoid;
}

.cbo-search-pdf-footer-img-info {
  height: auto;
  width: 100%;
  break-inside: avoid;
}

@media print {
  div,
  img {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

img {
  page-break-inside: avoid;
}

tr {
  page-break-after: always !important;
  page-break-before: always !important;
  page-break-inside: avoid !important;
}
</style>
